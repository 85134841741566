<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card flat>
      <v-card-title>
        Buchung bearbeiten
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0">
          <div class="mr-md-12">
            <booking-editor ref="bookingEditor" :initial-booking="booking">
            </booking-editor>
          </div>
        </v-row>
      </v-card-text>
      <v-divider class="mt-3" />
      <v-card-actions>
        <v-btn color="primary" elevation="0" @click="doBookingSubmission()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BookingEditor from "@/components/financial/BookingEditor";

export default {
  name: "BookingDetailView",
  components: { BookingEditor },
  methods: {
    ...mapActions("financial", [
      "updateBooking",
      "fetchAccounts",
      "fetchBankBookings"
    ]),
    doBookingSubmission() {
      if (this.$refs.bookingEditor.validate()) {
        let changedBooking = this.$refs.bookingEditor.getBooking();
        console.log("update", changedBooking, changedBooking.bank_booking);
        this.updateBooking(changedBooking);
        this.fetchAccounts(true);
        this.$router.push("/financial/bookings");
      } else {
        this.$vuetify.goTo(1, { duration: 650 });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("financial", { bookingGetter: "booking" }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    booking() {
      return this.bookingGetter(parseInt(this.$route.params.bookingId));
    }
  },
  mounted() {
    this.fetchAccounts();
    this.fetchBankBookings();
  }
};
</script>

<style scoped></style>
