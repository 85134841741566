<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card flat>
      <v-card-title>
        <v-chip dense class="mr-2">{{ settlement.status }}</v-chip> Abrechnung:
        {{ settlement.name }} / Zeitraum: {{ settlement.start_date }} bis
        {{ settlement.end_date }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <settlement-show :initial-settlement="settlement"> </settlement-show>
      </v-card-text>
      <v-divider class="mt-3" />
      <v-card-actions>
        <v-btn color="primary" elevation="0" @click="doSubmission()">
          Zurück
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SettlementShow from "@/components/financial/SettlementShow";

export default {
  name: "SettlementShowView",
  components: { SettlementShow },
  methods: {
    ...mapActions("financial", ["fetchSettlements"]),
    doSubmission() {
      this.$router.push("/financial/settlements");
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("financial", { settlementGetter: "settlement" }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    settlement() {
      return this.settlementGetter(parseInt(this.$route.params.settlementId));
    }
  },
  mounted() {
    this.fetchSettlements();
  }
};
</script>

<style scoped></style>
