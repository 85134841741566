<template>
  <div>
    <v-data-table
      dense
      :headers="accounts_headers"
      :items="accounts"
      item-key="id"
      class="elevation-1"
      :sort-by.sync="sortby"
      :sort-desc.sync="desc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            to="/financial/accounts/submission"
          >
            Neues Konto
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:body.prepend v-if="!isMobile">
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['fiscal_year']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['fullname']"
              label="Filter"
            ></v-text-field>
          </td>
          <td></td>
        </tr>
      </template>
      <template v-slot:item.move="{ item }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          @click.stop="move(item.id, -1)"
        >
          <v-icon small>
            mdi-arrow-down-thick
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          @click.stop="move(item.id, 1)"
        >
          <v-icon small>
            mdi-arrow-up-thick
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          :to="'/financial/accounts/' + item.id"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          @click.stop="showDeleteConfirm(item.id)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h6">
          Wirklich löschen?
        </v-card-title>

        <v-card-text>
          Soll das Konto wirklich gelöscht werden?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="deleteDialog = false">
            Nein
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteButtonClicked(deleteId)">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "BankOverviewAccounts",
  data() {
    return {
      deleteDialog: false,
      deleteId: null,
      filter: {},
      sortby: "order",
      desc: true,
      accounts_headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "move"
        },
        {
          text: "#",
          align: "start",
          value: "order",
          sortable: true
        },
        {
          text: "Jahr",
          align: "start",
          sortable: false,
          value: "fiscal_year",
          filter: f => {
            if (
              !this.filter["fiscal_year"] ||
              this.filter["fiscal_year"] == "" ||
              this.filter["fiscal_year"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["fiscal_year"] + "").toLowerCase());
          }
        },
        {
          text: "Konto",
          value: "fullname"
        },
        {
          text: "Kontostand",
          align: "end",
          value: "balance"
        },
        { text: "", align: "end", value: "actions", sortable: false }
      ]
    };
  },
  components: {},

  computed: {
    accounts() {
      return this.$store.state.financial.accounts;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("financial", ["deleteAccount", "patchAccount"]),
    showDeleteConfirm(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    deleteButtonClicked(id) {
      this.deleteDialog = false;
      this.deleteAccount(id);
    },
    move(id, direction) {
      const accounts = this.accounts.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        return 1;
      });
      accounts.forEach((element, index) => {
        let prev = index > 0 ? accounts[index - 1] : null;
        let next = index < accounts.length ? accounts[index + 1] : null;

        if (element.id == id) {
          if (direction == 1) {
            if (next && next.order == element.order + 1) {
              this.patchAccount({ id: next.id, order: next.order - 1 });
            }
            this.patchAccount({ id: element.id, order: element.order + 1 });
          } else if (direction == -1) {
            if (prev && prev.order == element.order - 1) {
              this.patchAccount({ id: prev.id, order: prev.order + 1 });
            }
            this.patchAccount({ id: element.id, order: element.order - 1 });
          }
        }
      });
    }
  }
};
</script>
