<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>Gesamtansicht:</v-card-title>
      <v-card-text>
        <div>Bank Startwert: 0 €</div>
        <div>Bank Differenz: {{ data.total }}</div>
        <div v-for="data in data.account_array" :key="data.fullname">
          Buchungskonto {{ data.fullname }}:
          {{ data.total }}
        </div>
      </v-card-text>
    </v-card>
    <v-card v-for="data in data.bank_array" :key="data.iban" class="mb-4">
      <v-card-title>Bankkonto: {{ data.iban }}</v-card-title>
      <v-card-text>
        <div>Startwert: 0 €</div>
        <div>Endwert: {{ data.total }}</div>
        <div>Bank-Buchungen:</div>
      </v-card-text>
      <v-data-table
        dense
        :headers="iban_booking_headers.concat(data.headers)"
        :items="data.bank_bookings.concat(data.total_row)"
        item-key="purpose"
        class="elevation-1"
        hide-default-footer
        :items-per-page="-1"
        :item-class="highlightBank"
      >
        <template v-slot:item.person="{ item }">
          <div v-if="item.booking">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              icon
              :to="'/financial/bookings/' + item.booking.id"
            >
              <v-icon small>
                mdi-cash-multiple
              </v-icon>
            </v-btn>
            <span v-if="item.booking.invoices.ids">
              <v-btn
                v-for="invoice in item.booking.invoices.ids"
                :key="invoice"
                color="primary"
                dark
                class="mb-2"
                icon
                :to="'/invoices/' + invoice"
              >
                <v-icon small>
                  account_balance
                </v-icon>
              </v-btn>
            </span>
          </div>
          <div v-else>{{ item.person }}</div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Parser from "@/utils/Parser";

function calculateTotal(account, iban, amount, bank_data) {
  if (account) {
    if (!bank_data[iban]["total_row"][0][account.fullname]) {
      bank_data[iban]["total_row"][0][account.fullname] = 0;
    }
    bank_data[iban]["total_row"][0][account.fullname] += amount;
  }
  return bank_data;
}

function createSublementBooking(booking, bankbooking, account, index, amount) {
  let data = {
    index: index,
    data: {
      ...bankbooking,
      date: null,
      purpose: "Buchung: " + booking.purpose,
      amount: null,
      person: null,
      booking: booking
    }
  };

  if (account) {
    data.data[account.fullname] = Parser.numberToString(amount, 2, "€");
  }
  return data;
}

function parseBankbookingData(booking, bankbooking, account, bank_data) {
  if (bankbooking) {
    const amount = Parser.DEstringToNumber(booking.amount);
    let iban = "";
    if (amount > 0) {
      iban = bankbooking.bank_destination_iban;
    } else if (amount < 0) {
      iban = bankbooking.bank_source_iban;
    }

    if (!bank_data[iban]) {
      bank_data[iban] = {
        iban: iban,
        total: 0,
        bank_bookings: [],
        used_accounts: [],
        headers: [],
        total_row: [{}]
      };
    }
    bank_data[iban]["total"] += amount;
    bank_data[iban]["total_row"][0]["amount"] = bank_data[iban]["total"];

    let bankbooking_already_in = false;
    let addnew = [];
    bank_data[iban]["bank_bookings"].forEach((element, index) => {
      if (element.id == bankbooking.id && element.date) {
        bankbooking_already_in = true;
        addnew.push(
          createSublementBooking(booking, bankbooking, account, index, amount)
        );
        bank_data = calculateTotal(account, iban, amount, bank_data);
      }
    });
    if (bankbooking_already_in) {
      addnew.forEach(element => {
        bank_data[iban]["bank_bookings"].splice(
          element.index + 1,
          0,
          element.data
        );
      });
    } else {
      bank_data[iban]["bank_bookings"].push({
        ...bankbooking,
        purpose: "Bank-Buchung: " + bankbooking.purpose,
        amount: bankbooking.amount + "€"
      });
      bank_data[iban]["bank_bookings"].push(
        createSublementBooking(booking, bankbooking, account, 0, amount).data
      );
      bank_data = calculateTotal(account, iban, amount, bank_data);
    }
    if (!bank_data[iban]["used_accounts"].includes(account.fullname)) {
      bank_data[iban]["used_accounts"].push(account.fullname);
      bank_data[iban]["headers"].push({
        text: account.fullname,
        align: "start",
        sortable: false,
        value: account.fullname
      });
    }
  }
  return bank_data;
}

function parseAccountData(booking, bankbooking, account, accounts_data) {
  if (account) {
    if (!accounts_data[account.id]) {
      accounts_data[account.id] = {
        ...account,
        total: 0,
        bookings: [],
        bank_bookings: []
      };
    }
    const amount = Parser.DEstringToNumber(booking.amount);
    accounts_data[account.id]["total"] += amount;

    accounts_data[account.id]["bookings"].push(booking);
    if (bankbooking) {
      accounts_data[account.id]["bank_bookings"].push(bankbooking);
    }
  }
  return accounts_data;
}

function cleanupNumberFormats(bank_data, accounts_data) {
  Object.values(bank_data).forEach(data => {
    data.total = Parser.numberToString(data.total, 2, "€");
    Object.entries(data.total_row[0]).forEach(([account, total]) => {
      console.log(data.total_row[0][account], account, total);
      data.total_row[0][account] = Parser.numberToString(total, 2, "€");
    });
  });

  Object.values(accounts_data).forEach(data => {
    data.total = Parser.numberToString(data.total, 2, "€");
  });

  return bank_data, accounts_data;
}

export default {
  name: "SettlementEditor",
  components: {},
  directives: {},
  props: {
    initialSettlement: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      settlement: {
        ...{},
        ...this.initialSettlement
      },
      iban_booking_headers: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Person",
          align: "start",
          sortable: false,
          value: "person"
        },
        {
          text: "Buchung",
          align: "start",
          sortable: false,
          value: "purpose"
        },
        {
          text: "Betrag",
          align: "start",
          sortable: false,
          value: "amount"
        }
      ],
      Parser: Parser
    };
  },
  watch: {
    initialSettlement: function(newVal) {
      this.settlement = { ...this.settlement, ...newVal };
    }
  },
  methods: {
    getSettlement() {
      return this.settlement;
    },
    highlightBank(item) {
      if (item.date) {
        return "v-row-group__header";
      }
      if (!item.purpose) {
        return "font-weight-bold";
      }
      return "";
    }
  },
  computed: {
    ...mapGetters("financial", ["account", "booking", "bankbooking"]),
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    data() {
      let accounts_data = {};
      let bank_data = {};
      let total = 0;
      if (this.settlement.bookings) {
        this.settlement.bookings.forEach(booking_id => {
          const booking = this.booking(booking_id);
          if (booking && booking.account1) {
            const account = this.account(booking.account1);
            const bankbooking = this.bankbooking(booking.bank_booking);

            total += Parser.DEstringToNumber(booking.amount);

            accounts_data = parseAccountData(
              booking,
              bankbooking,
              account,
              accounts_data
            );

            bank_data = parseBankbookingData(
              booking,
              bankbooking,
              account,
              bank_data
            );
          }
        });
      }
      total = Parser.numberToString(total, 2, "€");
      bank_data,
        (accounts_data = cleanupNumberFormats(bank_data, accounts_data));

      let account_array = [];
      for (const [, account_data] of Object.entries(accounts_data)) {
        account_array.push(account_data);
      }

      let bank_array = [];
      for (const [, data] of Object.entries(bank_data)) {
        bank_array.push(data);
      }

      return {
        account_array: account_array,
        bank_array: bank_array,
        total: total
      };
    }
  }
};
</script>

<style scoped></style>
