<template>
  <div>
    <v-list dense>
      <v-subheader>Aktionen</v-subheader>
      <v-list-item disabled v-if="sepa_update_updating">
        <v-list-item-icon>
          <v-icon>mdi-check-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >SEPA Bank Abfrage starten</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Startet die SEPA Bank Abfrage über das FinTS Protokoll. Deine
            Handynummer muss über Scouttools mit der Signal Messenger App
            verknüpft sein.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        ripple
        :selectable="false"
        @click="this.requestSepaUpdate"
        v-if="!sepa_update_updating"
      >
        <v-list-item-icon>
          <v-icon color="blue dark-5">mdi-check-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >SEPA Bank Abfrage starten</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Startet die SEPA Bank Abfrage über das FinTS Protokoll. Deine
            Handynummer muss über Scouttools mit der Signal Messenger App
            verknüpft sein.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-subheader>Aktions-Protokoll:</v-subheader>
      <v-list-item disabled v-if="!sepa_update_updating">
        <v-list-item-icon> </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Keine laufende Aktion</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Zur Zeit läuft keine Aktion.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item disabled v-if="sepa_update_updating">
        <v-list-item-icon>
          <v-progress-circular indeterminate></v-progress-circular>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >SEPA Bank Abfrage läuft</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Bitte schau in die Signal App auf deinem Handy für weitere
            Anweisungen der Bank. (z.B. TAN Eingabe)</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="action in action_log" :key="action.id">
        <v-list-item-icon>
          <v-icon color="blue dark-5">mdi-information-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >{{ action.time }} {{ action.name }}</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap">{{
            action.message
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import Parser from "@/utils/Parser";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BankOverviewActions",
  data() {
    return {
      action_log: []
    };
  },
  components: {},
  watch: {
    sepa_update_updating(newval, oldval) {
      const result = this.$store.state.financial.actions.sepa_update.result;
      if (newval === false && oldval === true) {
        if (result !== null) {
          this.action_log.push({
            id: this.action_log.length,
            message:
              "Es wurden " +
              result.bookings_received +
              " Buchungen abgefragt, davon waren " +
              result.bookings_saved +
              " neu und wurden in Scouttools übernommen.",
            time: Parser.parseDateTime(new Date()),
            name: "SEPA Bank Abfrage"
          });
        } else {
          console.log("error", result, this);
          this.action_log.push({
            id: this.action_log.length,
            message: "Fehler, abgebrochen",
            time: Parser.parseDateTime(new Date()),
            name: "SEPA Bank Abfrage"
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("financial", {
      actions_sepa_update_updating: "actions_sepa_update_updating"
    }),
    sepa_update_updating() {
      return this.actions_sepa_update_updating();
    },
    sepa_update() {
      return this.$store.state.financial.actions.sepa_update;
    }
  },
  methods: {
    ...mapActions("financial", ["requestSepaUpdate"])
  }
};
</script>
