<template>
  <div>
    <v-list dense>
      <v-subheader>Bank-Buchungen</v-subheader>
      <v-list-item v-if="bankbookings_without_bookings.length == 0">
        <v-list-item-icon
          ><v-icon color="blue dark-5"
            >mdi-check-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Anzahl gebucht</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Alle Bank-Buchungen sind verbucht.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap">Nicht gebucht</v-list-item-title>
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bankbookings_without_bookings.length }} von
            {{ this.bankbookings.length }} Bank-Buchungen sind nicht auf Konten
            gebucht.</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="booking in bankbookings_without_bookings"
            :key="booking.id"
          >
            {{
              booking.id + " - " + booking.purpose + " (" + booking.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="bankbookings_not_100_percent.length != 0">
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Falsch gebucht</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bankbookings_not_100_percent.length }} Bank-Buchungen sind
            nicht mit ihrem vollen Betrag verbucht.</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="booking in bankbookings_not_100_percent"
            :key="booking.id"
          >
            {{
              booking.id + " - " + booking.purpose + " (" + booking.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-subheader>Buchungen</v-subheader>
      <v-list-item v-if="bookings_not_with_bankbookings.length == 0">
        <v-list-item-icon
          ><v-icon color="blue dark-5"
            >mdi-check-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Anzahl gebucht</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Alle Buchungen referenzieren eine
            Bankbuchung.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Anzahl gebucht</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bookings_not_with_bankbookings.length }} von
            {{ this.bookings.length }} Buchungen referenzieren keine
            Bankbuchung.</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="booking in bookings_not_with_bankbookings"
            :key="booking.id"
          >
            {{
              booking.id + " - " + booking.purpose + " (" + booking.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="bookings_not_with_account == 0">
        <v-list-item-icon
          ><v-icon color="blue dark-5"
            >mdi-check-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Konto Verbindung</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Alle Buchungen referenzieren eine Konto.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Konto Verbindung</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bookings_not_with_account.length }} von
            {{ this.bookings.length }} Buchungen referenzieren kein
            Konto</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="booking in bookings_not_with_account"
            :key="booking.id"
          >
            {{
              booking.id + " - " + booking.purpose + " (" + booking.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="bookings_with_invoice_amount_wrong == 0">
        <v-list-item-icon
          ><v-icon color="blue dark-5"
            >mdi-check-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Verbundene Belege</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Alle Buchungen mit Belegen haben keine Abweichung zwischen
            Buchungs-Summe und Belegsumme.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Verbundene Belege</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bookings_with_invoice_amount_wrong.length }} von
            {{ this.bookings.length }} Buchungen weichen in der Buchungs-Summe
            von der Beleg-Summe ab.</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="booking in bookings_with_invoice_amount_wrong"
            :key="booking.id"
          >
            {{
              booking.id + " - " + booking.purpose + " (" + booking.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="bookings_missing_invoices_in_bookings.length == 0">
        <v-list-item-icon
          ><v-icon color="blue dark-5"
            >mdi-check-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Belege für Buchungen</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >Alle Belege sind in einer Buchung enthalten.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon
          ><v-icon color="error dark-5"
            >mdi-alert-circle</v-icon
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="text-wrap"
            >Belege für Buchungen</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap"
            >{{ this.bookings_missing_invoices_in_bookings.length }} von
            {{ this.invoices.length }} Belege sind in keiner Buchung
            referenziert</v-list-item-subtitle
          >
          <v-list-item-subtitle
            class="text-wrap"
            v-for="invoice in bookings_missing_invoices_in_bookings"
            :key="invoice.id"
          >
            {{
              invoice.id + " - " + invoice.usage + " (" + invoice.amount + ")"
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Parser from "@/utils/Parser";

function roundabs(num) {
  return Math.abs(Math.round((Number(num) + Number.EPSILON) * 100) / 100);
}
export default {
  name: "BankOverviewErrorchecks",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters("invoices", ["invoice"]),
    bankbookings() {
      return this.$store.state.financial.bankbookings;
    },
    bankbookings_without_bookings() {
      return this.bankbookings_stats.without_bookings;
    },
    bankbookings_not_100_percent() {
      return this.bankbookings_stats.not_100_percent;
    },
    bankbookings_stats() {
      let without_bookings = [];
      let not_100_percent = [];
      this.$store.state.financial.bankbookings.forEach(bankbooking => {
        if (bankbooking.amount_booked_percent == 0) {
          without_bookings.push(bankbooking);
        }

        if (
          bankbooking.amount_booked_percent != 0 &&
          bankbooking.amount_booked_percent != 100
        ) {
          not_100_percent.push(bankbooking);
          console.log("Not 100 %", bankbooking);
        }
      });
      return {
        without_bookings: without_bookings,
        not_100_percent: not_100_percent
      };
    },

    invoices() {
      return this.$store.state.invoices.invoices;
    },

    bookings() {
      return this.$store.state.financial.bookings;
    },
    bookings_not_with_bankbookings() {
      return this.bookings_stats.not_with_bankbookings;
    },
    bookings_not_with_account() {
      return this.bookings_stats.not_with_account;
    },
    bookings_with_invoice_amount_wrong() {
      return this.bookings_stats.with_invoice_amount_wrong;
    },
    bookings_missing_invoices_in_bookings() {
      return this.bookings_stats.missing_invoices_in_bookings;
    },
    bookings_stats() {
      let not_with_bankbookings = [];
      let invoices_in_bookings = [];
      let missing_invoices_in_bookings = [];
      let not_with_account = 0;
      let with_invoice_amount_wrong = [];
      this.$store.state.financial.bookings.forEach(booking => {
        if (booking.bank_booking == null) {
          not_with_bankbookings.push(booking);
        }
        if (booking.account1 == null) {
          not_with_account.push(booking);
        }

        if (
          booking.invoices &&
          booking.invoices.ids &&
          booking.invoices.ids.length > 0
        ) {
          let amount = 0;
          booking.invoices.ids.forEach(id => {
            const invoice = this.invoice(id);
            if (
              invoice &&
              invoice.status != "Approved" &&
              invoice.status != "Rejected"
            ) {
              amount = amount + Parser.DEstringToNumber(invoice.amount);
              if (!invoices_in_bookings.includes(id)) {
                invoices_in_bookings.push(invoice.id);
              }
            } else {
              console.log("Ignore", invoice);
            }
          });
          const booking_amount = Parser.DEstringToNumber(booking.amount);
          if (roundabs(booking_amount) != roundabs(amount)) {
            console.log("with_invoice_amount_wrong", booking, amount);
            with_invoice_amount_wrong.push(booking);
          }
        }
      });
      this.$store.state.invoices.invoices.forEach(invoice => {
        if (
          !invoices_in_bookings.includes(invoice.id) &&
          invoice.status != "Rejected" &&
          invoice.status != "Approved" &&
          invoice.status != "Open"
        ) {
          missing_invoices_in_bookings.push(invoice);
        }
      });
      return {
        not_with_bankbookings: not_with_bankbookings,
        not_with_account: not_with_account,
        with_invoice_amount_wrong: with_invoice_amount_wrong,
        missing_invoices_in_bookings: missing_invoices_in_bookings
      };
    }
  },
  methods: {}
};
</script>
