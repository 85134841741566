var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.auditlogs_headers,"items":_vm.auditlogs,"item-key":"id","sort-by":_vm.auditlogs_sortby,"sort-desc":_vm.auditlogs_desc,"show-expand":""},on:{"update:sortBy":function($event){_vm.auditlogs_sortby=$event},"update:sort-by":function($event){_vm.auditlogs_sortby=$event},"update:sortDesc":function($event){_vm.auditlogs_desc=$event},"update:sort-desc":function($event){_vm.auditlogs_desc=$event}},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_c('td'),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Filter"},model:{value:(_vm.filter['time']),callback:function ($$v) {_vm.$set(_vm.filter, 'time', $$v)},expression:"filter['time']"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Filter"},model:{value:(_vm.filter['action']),callback:function ($$v) {_vm.$set(_vm.filter, 'action', $$v)},expression:"filter['action']"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"clearable":"","items":[
              { value: 'models.account', text: 'Konto' },
              { value: 'models.booking', text: 'Buchung' },
              { value: 'models.bankbooking', text: 'Bankbuchung' },
              { value: 'models.settlement', text: 'Abrechnung' }
            ],"label":"Filter","chips":""},model:{value:(_vm.filter['obj']),callback:function ($$v) {_vm.$set(_vm.filter, 'obj', $$v)},expression:"filter['obj']"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Filter"},model:{value:(_vm.filter['obj_id']),callback:function ($$v) {_vm.$set(_vm.filter, 'obj_id', $$v)},expression:"filter['obj_id']"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Filter"},model:{value:(_vm.filter['obj_name']),callback:function ($$v) {_vm.$set(_vm.filter, 'obj_name', $$v)},expression:"filter['obj_name']"}})],1),_c('td')])]},proxy:true}:null,{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}})]},proxy:true},{key:"item.obj",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cleanupObj(item.obj))+" ")]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Änderungen")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('b',[_vm._v("IP:")]),_vm._v(" "+_vm._s(item.ip)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('b',[_vm._v("URL:")]),_vm._v(" "+_vm._s(item.url)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('b',[_vm._v("Benutzer:")]),_vm._v(" "+_vm._s(item.owner)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('b',[_vm._v("Geänderte Werte:")])]),_c('pre',[_vm._v(_vm._s(JSON.stringify(JSON.parse(item.delta), null, 2))+"\n            ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('b',[_vm._v("Werte:")])]),_c('pre',[_vm._v(_vm._s(JSON.stringify(JSON.parse(item.value), null, 2))+"\n            ")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }