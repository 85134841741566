<template>
  <div>
    <v-data-table
      dense
      :headers="auditlogs_headers"
      :items="auditlogs"
      item-key="id"
      class="elevation-1"
      :sort-by.sync="auditlogs_sortby"
      :sort-desc.sync="auditlogs_desc"
      show-expand
    >
      <template v-slot:body.prepend v-if="!isMobile">
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['time']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['action']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              clearable
              :items="[
                { value: 'models.account', text: 'Konto' },
                { value: 'models.booking', text: 'Buchung' },
                { value: 'models.bankbooking', text: 'Bankbuchung' },
                { value: 'models.settlement', text: 'Abrechnung' }
              ]"
              v-model="filter['obj']"
              label="Filter"
              chips
            ></v-autocomplete>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['obj_id']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['obj_name']"
              label="Filter"
            ></v-text-field>
          </td>
          <td></td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat> </v-toolbar>
      </template>
      <template v-slot:item.obj="{ item }">
        {{ cleanupObj(item.obj) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Änderungen</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <b>IP:</b> {{ item.ip }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">
                <b>URL:</b> {{ item.url }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">
                <b>Benutzer:</b> {{ item.owner }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">
                <b>Geänderte Werte:</b>
              </v-list-item-subtitle>
              <pre
                >{{ JSON.stringify(JSON.parse(item.delta), null, 2) }}
              </pre>
              <v-list-item-subtitle class="text-wrap">
                <b>Werte:</b>
              </v-list-item-subtitle>
              <pre
                >{{ JSON.stringify(JSON.parse(item.value), null, 2) }}
              </pre>
            </v-list-item-content>
          </v-list-item>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "BankOverviewAuditlogs",
  data() {
    return {
      auditlogs_sortby: "time",
      auditlogs_desc: true,
      filter: {}
    };
  },
  components: {},

  computed: {
    ...mapGetters("financial", ["auditlogs_realdiff"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    auditlogs() {
      return this.auditlogs_realdiff();
    },
    auditlogs_headers() {
      return [
        {
          text: "Id",
          sortable: true,
          value: "id"
        },
        {
          text: "Zeitpunkt",
          sortable: true,
          value: "time",
          filter: f => {
            if (
              !this.filter["time"] ||
              this.filter["time"] == "" ||
              this.filter["time"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["time"] + "").toLowerCase());
          }
        },
        {
          text: "Änderung",
          sortable: true,
          value: "action",
          filter: f => {
            if (
              !this.filter["action"] ||
              this.filter["action"] == "" ||
              this.filter["action"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["action"] + "").toLowerCase());
          }
        },
        {
          text: "Objekt-Klasse",
          value: "obj",
          filter: f => {
            if (
              !this.filter["obj"] ||
              this.filter["obj"] == "" ||
              this.filter["obj"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["obj"] + "").toLowerCase());
          }
        },
        {
          text: "Objekt-ID",
          value: "obj_id",
          filter: f => {
            if (
              !this.filter["obj_id"] ||
              this.filter["obj_id"] == "" ||
              this.filter["obj_id"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["obj_id"] + "").toLowerCase());
          }
        },
        {
          text: "Objekt-Name",
          value: "obj_name",
          filter: f => {
            if (
              !this.filter["obj_name"] ||
              this.filter["obj_name"] == "" ||
              this.filter["obj_name"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["obj_name"] + "").toLowerCase());
          }
        },
        { text: "", align: "end", value: "actions", sortable: false }
      ];
    }
  },
  methods: {
    cleanupObj(str) {
      return str
        .replace("<class 'backend.models.", "")
        .replace("'>", "")
        .replace(/^Settlement$/, "Abrechnung")
        .replace(/^Booking$/, "Buchung")
        .replace(/^Account$/, "Konto")
        .replace(/^BankBooking$/, "Bank-Buchung");
    }
  }
};
</script>
