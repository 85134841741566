<template>
  <v-row class="mx-0 justify-center justify-lg-start" style="width: 100%">
    <v-card flat class="mt-md-3 mx-md-8 mx-lg-16" width="100%">
      <v-card-title class="justify-center justify-lg-start">
        <span>Finanzen</span>
      </v-card-title>

      <div class="sticky-tabs">
        <v-tabs :grow="isMobile" show-arrows v-model="tab">
          <v-tab key="/financial/accounts" to="/financial/accounts">
            Konten
          </v-tab>
          <v-tab key="/financial/bookings" to="/financial/bookings">
            Buchungen
          </v-tab>
          <v-tab key="/financial/bankbookings" to="/financial/bankbookings">
            Bank-Buchungen
          </v-tab>
          <v-tab key="/financial/settlements" to="/financial/settlements">
            Abrechnungen
          </v-tab>
          <v-tab key="/financial/actions" to="/financial/actions">
            Wartungs-Aktionen
          </v-tab>
          <v-tab key="/financial/errorchecks" to="/financial/errorchecks">
            Fehlerprüfungen
          </v-tab>
          <v-tab key="/financial/auditlogs" to="/financial/auditlogs">
            Auditlog
          </v-tab>
        </v-tabs>
        <v-divider />
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item id="/financial/accounts">
          <router-view v-if="tab === '/financial/accounts'" />
        </v-tab-item>
        <v-tab-item id="/financial/bookings">
          <router-view v-if="tab === '/financial/bookings'" />
        </v-tab-item>
        <v-tab-item id="/financial/bankbookings">
          <router-view v-if="tab === '/financial/bankbookings'" />
        </v-tab-item>
        <v-tab-item id="/financial/settlements">
          <router-view v-if="tab === '/financial/settlements'" />
        </v-tab-item>
        <v-tab-item id="/financial/actions">
          <router-view v-if="tab === '/financial/actions'" />
        </v-tab-item>
        <v-tab-item id="/financial/errorchecks">
          <router-view v-if="tab === '/financial/errorchecks'" />
        </v-tab-item>
        <v-tab-item id="/financial/auditlogs">
          <router-view v-if="tab === '/financial/auditlogs'" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Financial",
  components: {},
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("financial", [
      "fetchAccounts",
      "fetchBookings",
      "fetchBankBookings",
      "fetchAuditlogs",
      "fetchSettlements"
    ]),
    ...mapActions("invoices", ["fetchInvoices", "fetchTags"]),
    ...mapActions("users", ["refreshUsers"])
  },
  mounted() {
    this.refreshUsers();
    this.fetchAccounts();
    this.fetchBookings();
    this.fetchBankBookings();
    this.fetchAuditlogs();
    this.fetchInvoices();
    this.fetchTags();
    this.fetchSettlements();
  }
};
</script>

<style>
.sticky-tabs {
  position: sticky;
  top: 3rem;
  z-index: 2;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
