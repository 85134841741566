<template>
  <div>
    <v-form ref="accountForm" lazy-validation style="max-width: 600px">
      <v-text-field
        label="Name"
        prepend-icon="notes"
        v-model="account.name"
        :rules="[v => !!v || 'Bitte Namen angeben']"
        class="mb-1"
      />
      <v-text-field
        label="Beschreibung"
        prepend-icon="notes"
        v-model="account.description"
        class="mb-1"
      />
      <v-text-field
        label="Jahr"
        prepend-icon="notes"
        v-model="account.fiscal_year"
        class="mb-1"
      />
      <v-select
        label="Übergeordnetes-Konto"
        prepend-icon="notes"
        v-model="account.parent"
        :items="parents"
        item-text="name"
        item-value="id"
      ></v-select>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountEditor",
  components: {},
  directives: {},
  props: {
    initialAccount: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      account: {
        ...{},
        ...this.initialAccount
      }
    };
  },
  watch: {
    initialAccount: function(newVal) {
      this.account = { ...this.account, ...newVal };
    }
  },
  methods: {
    validate() {
      return this.$refs.accountForm.validate();
    },
    getAccount() {
      return this.account;
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    parents() {
      return this.$store.state.financial.accounts.map(account => {
        return { name: account.fullname, id: account.id };
      });
    }
  }
};
</script>

<style scoped></style>
