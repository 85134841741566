<template>
  <div>
    <v-data-table
      dense
      :headers="bankbookings_headers"
      :items="bankbookings_extended"
      v-model="bankbookings_selected"
      item-key="id"
      class="elevation-1"
      show-select
      show-expand
      :sort-by.sync="bankbookings_sortby"
      :sort-desc.sync="bankbookings_desc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-app-bar
          prominent
          v-if="bankbookings_selected && bankbookings_selected.length > 0"
          flat
          class="sticky-tabs elevation-2 pt-4"
          color="info"
        >
          <v-row align="center">
            <v-col :cols="isMobile ? '12' : '8'">
              <v-autocomplete
                class="ml-3 pb-0 mb-1"
                solo
                hide-details
                v-model="bankbookings_actions_selected"
                :items="accounts_fullnames"
                :disabled="
                  !(bankbookings_selected && bankbookings_selected.length > 0)
                "
                :label="
                  !(bankbookings_selected && bankbookings_selected.length > 0)
                    ? 'Bank-Buchung auswählen zum buchen'
                    : 'Buche ausgewählte Bank-Buchungen auf'
                "
                chips
              >
                <template v-slot:append-outer v-if="!isMobile">
                  <v-btn
                    depressed
                    tile
                    color="primary"
                    class="ma-0"
                    :disabled="
                      !(
                        bankbookings_selected &&
                        bankbookings_selected.length > 0
                      )
                    "
                    @click="bankbookings_actions_click"
                  >
                    Los
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-btn
                v-if="isMobile"
                depressed
                tile
                color="primary"
                class="ma-0 ml-3"
                :disabled="
                  !(bankbookings_selected && bankbookings_selected.length > 0)
                "
                @click="bankbookings_actions_click"
              >
                Los
              </v-btn>
            </v-col>
          </v-row>
        </v-app-bar>
      </template>
      <template v-slot:body.prepend v-if="!isMobile">
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['date']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['purpose']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['amount']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['bank_source_iban']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['bank_destination_iban']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-select
              clearable
              :items="[
                { text: '100 % verbucht', value: 'ok' },
                { text: 'Falsch verbucht', value: 'wrong' },
                { text: 'Nicht verbucht', value: 'empty' }
              ]"
              v-model="filter['amount_booked_percent']"
              label="Filter"
            ></v-select>
          </td>
        </tr>
      </template>
      <template v-slot:item.amount_booked_percent="{ item }">
        {{ item.amount_booked_percent }} %
        <v-icon color="blue dark-5" v-if="item.amount_booked_percent == 100">
          mdi-check-circle
        </v-icon>
        <v-icon color="grey lighten-2" v-if="item.amount_booked_percent == 0">
          mdi-check-circle-outline
        </v-icon>
        <v-icon
          color="error lighten-2"
          v-if="
            item.amount_booked_percent < 0 ||
              (item.amount_booked_percent != 0 &&
                item.amount_booked_percent != 100)
          "
        >
          mdi-alert-circle
        </v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-for="invoice in item.invoices" :key="invoice.id">
            {{ invoice.invoice.usage }}
            /
            <span v-for="tag in invoice.invoice.tags" :key="tag.id">{{
              tag.name
            }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import clone from "lodash/clone";
export default {
  name: "BankOverviewBankbooking",
  data() {
    return {
      bankbookings_selected: [],
      bankbookings_actions_selected: null,
      bankbookings_sortby: "date",
      bankbookings_desc: true,
      filter: {}
    };
  },
  components: {},
  computed: {
    ...mapGetters("invoices", ["invoice", "tag"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    bankbookings() {
      return this.$store.state.financial.bankbookings;
    },
    bankbookings_extended() {
      let bankbookings = [];
      this.bankbookings.forEach(booking => {
        let bk = clone(booking);
        if (bk.bank_source_iban == "DE11547900000000413216") {
          bk.bank_source_iban = "Stammeskonto";
        } else if (bk.bank_source_iban == "DE75547900000014229132") {
          bk.bank_source_iban = "Beitragskonto";
        } else {
          bk.bank_source_iban = bk.person;
        }

        if (bk.bank_destination_iban == "DE11547900000000413216") {
          bk.bank_destination_iban = "Stammeskonto";
        } else if (bk.bank_destination_iban == "DE75547900000014229132") {
          bk.bank_destination_iban = "Beitragskonto";
        } else {
          bk.bank_destination_iban = bk.person;
        }

        bk.invoices = [];
        if (bk.purpose.startsWith("Belege: ")) {
          let purpose = bk.purpose.replace("Belege: ", "");
          let invoices = purpose.split(",");

          invoices.forEach(str => {
            const id = str.substr(5);
            let invoice = clone(this.invoice(Number(id)));
            if (invoice) {
              let tags = [];
              invoice.tags.forEach(id => {
                tags.push(this.tag(id));
              });
              invoice.tags = tags;
              bk.invoices.push({ id: id, invoice: invoice });
            }
          });
        }

        bankbookings.push(bk);
      });
      return bankbookings;
    },
    accounts_fullnames() {
      let actions = [{ text: " - ", value: -1 }];
      this.$store.state.financial.accounts.forEach(account => {
        actions.push({ text: account.fullname, value: account.id });
      });
      return actions;
    },
    bankbookings_headers() {
      return [
        {
          text: "Datum",
          align: "start",
          sortable: true,
          value: "date",
          filter: f => {
            if (
              !this.filter["date"] ||
              this.filter["date"] == "" ||
              this.filter["date"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["date"] + "").toLowerCase());
          }
        },
        {
          text: "Verwendungszweck",
          align: "start",
          sortable: true,
          value: "purpose",
          filter: f => {
            if (
              !this.filter["purpose"] ||
              this.filter["purpose"] == "" ||
              this.filter["purpose"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["purpose"] + "").toLowerCase());
          }
        },
        {
          text: "Betrag",
          align: "end",
          sortable: true,
          value: "amount",
          filter: f => {
            if (
              !this.filter["amount"] ||
              this.filter["amount"] == "" ||
              this.filter["amount"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["amount"] + "").toLowerCase());
          }
        },
        {
          text: "Von",
          align: "start",
          sortable: true,
          value: "bank_source_iban",
          filter: f => {
            if (
              !this.filter["bank_source_iban"] ||
              this.filter["bank_source_iban"] == "" ||
              this.filter["bank_source_iban"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["bank_source_iban"] + "").toLowerCase());
          }
        },
        {
          text: "An",
          align: "start",
          sortable: true,
          value: "bank_destination_iban",
          filter: f => {
            if (
              !this.filter["bank_destination_iban"] ||
              this.filter["bank_destination_iban"] == "" ||
              this.filter["bank_destination_iban"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes(
                (this.filter["bank_destination_iban"] + "").toLowerCase()
              );
          }
        },
        {
          text: "% Verbucht",
          align: "end",
          sortable: true,
          value: "amount_booked_percent",
          filter: f => {
            if (
              !this.filter["amount_booked_percent"] ||
              this.filter["amount_booked_percent"] == "" ||
              this.filter["amount_booked_percent"] == " - "
            ) {
              return true;
            }

            if (this.filter["amount_booked_percent"] == "ok") {
              return f == 100;
            } else if (this.filter["amount_booked_percent"] == "wrong") {
              return f != 0 && f != 100;
            } else if (this.filter["amount_booked_percent"] == "empty") {
              return f == 0;
            }

            return true;
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions("financial", [
      "fetchAccounts",
      "fetchBankBookings",
      "bankBookingCreateBooking"
    ]),
    bankbookings_actions_click() {
      if (
        this.bankbookings_actions_selected &&
        this.bankbookings_actions_selected > 0 &&
        this.bankbookings_selected &&
        this.bankbookings_selected.length > 0
      ) {
        let bankbookings_ids = [];
        this.bankbookings_selected.forEach(booking => {
          bankbookings_ids.push(booking.id);
        });
        this.bankBookingCreateBooking({
          account_id: this.bankbookings_actions_selected,
          bankbooking_ids: bankbookings_ids
        });
        this.bankbookings_selected = [];
        this.bankbookings_actions_selected = null;
      }
    }
  }
};
</script>
