<template>
  <div>
    <v-data-table
      dense
      :headers="bookings_headers"
      :items="bookings_with_foreigns"
      v-model="bookings_selected"
      show-select
      show-expand
      item-key="id"
      class="elevation-1"
      multi-sort
      :sort-by.sync="bookings_sortby"
      :sort-desc.sync="bookings_desc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            to="/financial/bookings/submission"
            class="mb-2"
          >
            Neue Buchung
          </v-btn>
        </v-toolbar>
        <v-app-bar
          prominent
          v-if="bookings_selected && bookings_selected.length > 0"
          flat
          class="sticky-tabs elevation-2 pt-4"
          color="info"
        >
          <v-row align="center">
            <v-col :cols="isMobile ? '12' : '8'">
              <v-autocomplete
                class="ml-3 pb-0 mb-1"
                solo
                hide-details
                v-model="bookings_actions_selected"
                :items="accounts.actions"
                :disabled="!(bookings_selected && bookings_selected.length > 0)"
                label="Aktion auswählen"
                chips
              >
                <template v-slot:append-outer v-if="!isMobile">
                  <v-btn
                    depressed
                    tile
                    class="ma-0"
                    :disabled="
                      !(bookings_selected && bookings_selected.length > 0)
                    "
                    @click="bookings_actions_click"
                  >
                    Los
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-btn
                v-if="isMobile"
                depressed
                tile
                class="ma-0 ml-3"
                :disabled="!(bookings_selected && bookings_selected.length > 0)"
                @click="bookings_actions_click"
              >
                Los
              </v-btn>
            </v-col>
          </v-row>
        </v-app-bar>
      </template>
      <template v-slot:body.prepend v-if="!isMobile">
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['date']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['purpose']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['amount']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              clearable
              :items="accounts.filter"
              item-value="text"
              v-model="filter['account1.fullname']"
              label="Filter"
            ></v-autocomplete>
          </td>
          <td>
            <v-text-field
              type="text"
              v-model="filter['bank_booking.person']"
              label="Filter"
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              clearable
              :items="filter_multiples"
              v-model="filter['filter_multiples']"
              label="Filter"
              chips
              multiple
            ></v-autocomplete>
          </td>
        </tr>
      </template>
      <template v-slot:item.bank_booking="{ item }">
        <v-icon color="blue darken-3" v-if="item.bank_booking">
          mdi-cash-multiple
        </v-icon>
        <v-icon color="grey lighten-2" v-if="!item.bank_booking">
          mdi-cash-multiple
        </v-icon>
        <v-icon
          color="blue darken-3"
          v-if="item.invoices && item.invoices.length"
        >
          account_balance
        </v-icon>
        <v-icon
          color="grey lighten-2"
          v-if="!(item.invoices && item.invoices.length)"
        >
          account_balance
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          :to="'/financial/bookings/' + item.id"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          @click.stop="showDeleteConfirm(item.id)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" v-if="item.invoices">
          <div class="d-flex justify-start flex-wrap">
            <v-card
              class="ma-1"
              max-width="200"
              v-for="invoice in item.invoices"
              :key="invoice.id"
              rounded
              :to="'/invoices/' + invoice.id"
            >
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-chip
                        class="ma-2"
                        dense
                        v-for="tag in invoice.tags"
                        :key="tag.id"
                        color="primary"
                      >
                        {{ tag.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-title>{{ invoice.usage }}</v-list-item-title>
                    <v-list-item-subtitle class="mb-2">{{
                      invoice.amount
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap">{{
                      invoice.comment
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h6">
          Wirklich löschen?
        </v-card-title>

        <v-card-text>
          Soll die Buchung wirklich gelöscht werden?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="deleteDialog = false">
            Nein
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteButtonClicked(deleteId)">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import clone from "lodash/clone";
export default {
  name: "Bookings",
  data() {
    return {
      filter: {},
      bookings_selected: [],
      bookings_actions_selected: null,
      bookings_sortby: ["date", "bank_booking.person"],
      bookings_desc: [true, true],
      deleteDialog: false,
      deleteId: null
    };
  },
  components: {},
  computed: {
    ...mapGetters("financial", ["bookings"]),
    ...mapGetters("invoices", ["invoice", "tag"]),
    ...mapGetters("financial", {
      getAccount: "account",
      getBankBooking: "bankbooking"
    }),
    filter_multiples() {
      let filters = [];
      filters.push({ text: "Mit Bankbuchung", value: "with_bankbooking" });
      filters.push({ text: "Ohne Bankbuchung", value: "without_bankbooking" });
      filters.push({ text: "Mit Belege", value: "with_invoices" });
      filters.push({ text: "Ohne Belege", value: "without_invoices" });
      return filters;
    },
    accounts() {
      let filter = [{ text: "<Leer>", value: "-" }];
      let actions = [
        { text: "Löschen", value: "Löschen" },
        { text: "Duplizieren", value: "Duplizieren" }
      ];
      this.$store.state.financial.accounts.forEach(account => {
        filter.push({ text: account.fullname, value: account.id });
        actions.push({
          text: "Umbuchen auf " + account.fullname,
          value: account.id
        });
      });
      return { filter: filter, actions: actions };
    },
    bookings() {
      return this.$store.state.financial.bookings;
    },
    bookings_with_foreigns() {
      let bookings_with_foreigns = [];
      for (
        let index = 0;
        index < this.$store.state.financial.bookings.length;
        index++
      ) {
        const booking = this.$store.state.financial.bookings[index];
        let copy = clone(booking);
        if (booking.account1 !== null) {
          copy.account1 = this.getAccount(booking.account1);
        }
        if (booking.bank_booking !== null) {
          copy.bank_booking = this.getBankBooking(booking.bank_booking);
        }
        if (booking.invoices) {
          let invoices = [];
          booking.invoices.ids.forEach(id => {
            let invoice = clone(this.invoice(id));
            if (invoice) {
              let tags = [];
              invoice.tags.forEach(tagid => {
                tags.push(clone(this.tag(tagid)));
              });
              invoice.tags = tags;
              invoices.push(invoice);
            }
          });
          copy.invoices = invoices;
        }
        bookings_with_foreigns.push(copy);
      }
      return bookings_with_foreigns;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    bookings_headers() {
      return [
        {
          text: "Datum",
          align: "start",
          sortable: true,
          value: "date",
          filter: f => {
            if (
              !this.filter["date"] ||
              this.filter["date"] == "" ||
              this.filter["date"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["date"] + "").toLowerCase());
          }
        },
        {
          text: "Verwendungszweck",
          align: "start",
          sortable: true,
          value: "purpose",
          filter: f => {
            if (
              !this.filter["purpose"] ||
              this.filter["purpose"] == "" ||
              this.filter["purpose"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["purpose"] + "").toLowerCase());
          }
        },
        {
          text: "Betrag",
          align: "end",
          sortable: true,
          value: "amount",
          filter: f => {
            if (
              !this.filter["amount"] ||
              this.filter["amount"] == "" ||
              this.filter["amount"] == " - "
            ) {
              return true;
            }
            return (f + "")
              .toLowerCase()
              .includes((this.filter["amount"] + "").toLowerCase());
          }
        },
        {
          text: "Konto",
          sortable: true,
          value: "account1.fullname",
          width: "50%",
          filter: f => {
            if (
              !this.filter["account1.fullname"] ||
              this.filter["account1.fullname"] == "" ||
              this.filter["account1.fullname"] == " - "
            ) {
              return true;
            }
            if (this.filter["account1.fullname"] == "<Leer>") {
              return f === undefined || f === null || null == "";
            }
            return (
              (f + "").toLowerCase() ==
              (this.filter["account1.fullname"] + "").toLowerCase()
            );
          }
        },
        {
          text: "Person",
          sortable: true,
          value: "bank_booking.person",
          filter: f => {
            if (
              !this.filter["bank_booking.person"] ||
              this.filter["bank_booking.person"] == "" ||
              this.filter["bank_booking.person"] == " - "
            ) {
              return true;
            }
            if (this.filter["bank_booking.person"] == "<Leer>") {
              return f === undefined || f === null || null == "";
            }
            return (f + "")
              .toLowerCase()
              .includes(
                (this.filter["bank_booking.person"] + "").toLowerCase()
              );
          }
        },
        {
          sortable: false,
          value: "bank_booking",
          filter: (f, _, item) => {
            let retval = true;

            if (this.filter["filter_multiples"]) {
              if (
                this.filter["filter_multiples"].includes("with_bankbooking")
              ) {
                retval = retval && f && true;
              } else if (
                this.filter["filter_multiples"].includes("without_bankbooking")
              ) {
                retval = retval && !f && true;
              }

              if (this.filter["filter_multiples"].includes("with_invoices")) {
                retval = retval && item.invoices.length > 0 && true;
              } else if (
                this.filter["filter_multiples"].includes("without_invoices")
              ) {
                retval = retval && item.invoices.length == 0 && true;
              }
            }
            return retval;
          }
        },
        { text: "", align: "end", value: "actions", sortable: false }
      ];
    }
  },
  methods: {
    ...mapActions("financial", [
      "fetchAccounts",
      "fetchBankBookings",
      "fetchBookings",
      "deleteBookings",
      "duplicateBookings",
      "bookBookings",
      "deleteBooking"
    ]),
    showDeleteConfirm(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    deleteButtonClicked(id) {
      this.deleteDialog = false;
      this.deleteBooking(id);
    },
    bookings_actions_click() {
      if (
        this.bookings_actions_selected &&
        this.bookings_selected &&
        this.bookings_selected.length > 0
      ) {
        let bookings_ids = [];
        this.bookings_selected.forEach(booking => {
          bookings_ids.push(booking.id);
        });

        if (this.bookings_actions_selected == "Löschen") {
          this.deleteBookings(bookings_ids);
        } else if (this.bookings_actions_selected == "Duplizieren") {
          this.duplicateBookings(bookings_ids);
        } else {
          this.bookBookings({
            ids: bookings_ids,
            account: this.bookings_actions_selected
          });
        }

        this.bookings_selected = [];
        this.bookings_actions_selected = null;
      }
    }
  }
};
</script>
