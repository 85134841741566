var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"bookingForm",staticStyle:{"max-width":"600px"},attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Verwendungszweck","prepend-icon":"notes","rules":[function (v) { return !!v || 'Bitte Verwendungszweck angeben'; }]},model:{value:(_vm.booking.purpose),callback:function ($$v) {_vm.$set(_vm.booking, "purpose", $$v)},expression:"booking.purpose"}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Beschreibung","prepend-icon":"notes"},model:{value:(_vm.booking.description),callback:function ($$v) {_vm.$set(_vm.booking, "description", $$v)},expression:"booking.description"}}),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyConfig),expression:"moneyConfig"}],staticClass:"mb-1",attrs:{"label":"Betrag","prepend-icon":"euro","inputmode":"numeric"},model:{value:(_vm.booking.amount),callback:function ($$v) {_vm.$set(_vm.booking, "amount", $$v)},expression:"booking.amount"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"label":"Datum","prepend-icon":"event","readonly":"","rules":[function (v) { return !!v || 'Bitte Datum angeben'; }]},model:{value:(_vm.booking.date),callback:function ($$v) {_vm.$set(_vm.booking, "date", $$v)},expression:"booking.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_datePicker),callback:function ($$v) {_vm.start_datePicker=$$v},expression:"start_datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.start_datePicker = false}},model:{value:(_vm.booking.date),callback:function ($$v) {_vm.$set(_vm.booking, "date", $$v)},expression:"booking.date"}})],1),_c('v-select',{attrs:{"label":"Buchungs-Konto","prepend-icon":"mdi-cash-multiple","items":_vm.accounts,"item-text":"name","item-value":"id"},model:{value:(_vm.booking.account1),callback:function ($$v) {_vm.$set(_vm.booking, "account1", $$v)},expression:"booking.account1"}}),_c('v-select',{attrs:{"label":"Bank-Buchung","prepend-icon":"mdi-cash-multiple","items":_vm.bank_bookings,"item-text":"name","item-value":"id","clearable":true},model:{value:(_vm.booking.bank_booking),callback:function ($$v) {_vm.$set(_vm.booking, "bank_booking", $$v)},expression:"booking.bank_booking"}}),(_vm.booking.invoices)?_c('v-autocomplete',{attrs:{"items":_vm.invoices,"label":"Belege","clearable":"","chips":"","deletable-chips":"","multiple":"","small-chips":"","prepend-icon":"account_balance","item-value":"id","item-text":"name"},model:{value:(_vm.booking.invoices.ids),callback:function ($$v) {_vm.$set(_vm.booking.invoices, "ids", $$v)},expression:"booking.invoices.ids"}}):_vm._e()],1),(
      _vm.booking.invoices &&
        _vm.booking.invoices.ids &&
        _vm.booking.invoices.ids.length > 0
    )?_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-data-table',{staticClass:"v-clickable",attrs:{"dense":"","headers":_vm.invoices_selected_headers,"items":_vm.invoices_selected,"disable-pagination":true,"item-key":"id","hide-default-footer":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.submitter",fn:function(ref){
    var item = ref.item;
return [(item.submitter)?_c('avatar',{staticClass:"ma-2",attrs:{"size":"25","user-id":item.submitter}}):_vm._e()]}}],null,false,1268300879)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }