<template>
  <div>
    <v-form ref="bookingForm" lazy-validation style="max-width: 600px">
      <v-text-field
        label="Verwendungszweck"
        prepend-icon="notes"
        v-model="booking.purpose"
        :rules="[v => !!v || 'Bitte Verwendungszweck angeben']"
        class="mb-1"
      />
      <v-text-field
        label="Beschreibung"
        prepend-icon="notes"
        v-model="booking.description"
        class="mb-1"
      />
      <v-text-field
        label="Betrag"
        prepend-icon="euro"
        v-money="moneyConfig"
        v-model.lazy="booking.amount"
        inputmode="numeric"
        class="mb-1"
      />
      <v-menu
        v-model="start_datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="booking.date"
            label="Datum"
            prepend-icon="event"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[v => !!v || 'Bitte Datum angeben']"
            class="mb-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="booking.date"
          @input="start_datePicker = false"
        ></v-date-picker>
      </v-menu>
      <v-select
        label="Buchungs-Konto"
        prepend-icon="mdi-cash-multiple"
        v-model="booking.account1"
        :items="accounts"
        item-text="name"
        item-value="id"
      ></v-select>
      <v-select
        label="Bank-Buchung"
        prepend-icon="mdi-cash-multiple"
        v-model="booking.bank_booking"
        :items="bank_bookings"
        item-text="name"
        item-value="id"
        :clearable="true"
      ></v-select>
      <v-autocomplete
        :items="invoices"
        label="Belege"
        clearable
        chips
        deletable-chips
        multiple
        small-chips
        v-model="booking.invoices.ids"
        prepend-icon="account_balance"
        item-value="id"
        item-text="name"
        v-if="booking.invoices"
      ></v-autocomplete>
    </v-form>
    <div
      v-if="
        booking.invoices &&
          booking.invoices.ids &&
          booking.invoices.ids.length > 0
      "
      class="d-flex flex-wrap"
    >
      <v-data-table
        dense
        :headers="invoices_selected_headers"
        :items="invoices_selected"
        :disable-pagination="true"
        item-key="id"
        hide-default-footer
        @click:row="handleClick"
        class="v-clickable"
      >
        <template v-slot:item.submitter="{ item }">
          <avatar
            v-if="item.submitter"
            class="ma-2"
            size="25"
            :user-id="item.submitter"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VMoney } from "v-money";
import Parser from "@/utils/Parser";
import Avatar from "@/components/user/Avatar";

export default {
  name: "BookingEditor",
  components: { Avatar },
  directives: { money: VMoney },
  props: {
    initialBooking: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      start_datePicker: false,
      booking: {
        ...{},
        ...this.initialBooking
      },
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        suffix: " €",
        precision: 2
      },
      Parser: Parser,
      invoices_selected_headers: [
        {
          text: "Person",
          align: "start",
          sortable: false,
          value: "submitter"
        },
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Beschreibung",
          align: "start",
          sortable: false,
          value: "usage"
        },
        {
          text: "Betrag",
          align: "start",
          sortable: false,
          value: "amount"
        }
      ]
    };
  },
  watch: {
    initialBooking: {
      immediate: true,
      handler: function(newVal) {
        this.booking = { ...this.booking, ...newVal };
        if (
          this.booking.invoices === undefined ||
          this.booking.invoices === null ||
          this.booking.invoices.ids === undefined ||
          this.booking.invoices.ids === null
        ) {
          this.booking.invoices = {};
          this.booking.invoices.ids = [];
        }
        console.log("Booking", this.booking);
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.bookingForm.validate();
    },
    handleClick(value) {
      if (value.id) {
        this.$router.push({ path: "/invoices/" + value.id });
      }
    },
    getBooking() {
      this.booking.amount = Parser.DEstringToNumber(this.booking.amount);
      return this.booking;
    },
    tags_of_invoice(ids) {
      let tags = [];
      if (ids) {
        ids.forEach(id => {
          tags.push(this.tag(id).name);
        });
      }

      return tags;
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    ...mapGetters("invoices", ["invoice", "tag"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    invoices_sum() {
      let sum = 0;
      if (this.booking.invoices && this.booking.invoices.ids) {
        this.booking.invoices.ids.forEach(id => {
          const invoice = this.invoice(id);
          if (invoice && invoice.amount) {
            sum = sum - Parser.DEstringToNumber(invoice.amount);
          }
        });
      }
      return Parser.numberToString(sum, 2, "€");
    },
    bank_bookings() {
      return this.$store.state.financial.bankbookings.map(booking => {
        return {
          name:
            booking.purpose + " (" + booking.id + "): " + booking.amount + "€",
          id: booking.id
        };
      });
    },
    accounts() {
      return this.$store.state.financial.accounts.map(account => {
        return { name: account.fullname, id: account.id };
      });
    },
    invoices_selected() {
      let invoices = [];
      let total = 0;
      this.booking.invoices.ids.forEach(id => {
        const invoice = this.invoice(id);
        if (invoice && invoice.amount) {
          invoices.push({
            ...invoice,
            amount: Parser.DEnumberToString(invoice.amount, 2, "€")
          });
          total = total + Parser.DEstringToNumber(invoice.amount);
        }
      });
      total = Parser.numberToString(total, 2, "€");
      invoices.push({ amount: total });
      return invoices;
    },
    invoices() {
      return this.$store.state.invoices.invoices.map(invoice => {
        return {
          name:
            invoice.usage +
            " (" +
            invoice.id +
            "): " +
            Parser.DEnumberToString(invoice.amount, 2, "€"),
          id: invoice.id
        };
      });
    }
  }
};
</script>

<style scoped>
.v-clickable {
  cursor: pointer;
}
</style>
