<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card flat>
      <v-card-title>
        Abrechnung bearbeiten
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0">
          <div style="width: 600px" class="mr-md-12">
            <settlement-editor
              ref="settlementEditor"
              :initial-settlement="settlement"
            >
            </settlement-editor>
          </div>
        </v-row>
      </v-card-text>
      <v-divider class="mt-3" />
      <v-card-actions>
        <v-btn color="primary" elevation="0" @click="doSubmission()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SettlementEditor from "@/components/financial/SettlementEditor";

export default {
  name: "SettlementDetailView",
  components: { SettlementEditor },
  methods: {
    ...mapActions("financial", ["updateSettlement", "fetchSettlements"]),
    doSubmission() {
      if (this.$refs.settlementEditor.validate()) {
        let changedSettlement = this.$refs.settlementEditor.getSettlement();
        this.updateSettlement(changedSettlement);
        this.$router.push("/financial/settlements");
      } else {
        this.$vuetify.goTo(1, { duration: 650 });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("financial", { settlementGetter: "settlement" }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    settlement() {
      return this.settlementGetter(parseInt(this.$route.params.settlementId));
    }
  },
  mounted() {
    this.fetchSettlements();
  }
};
</script>

<style scoped></style>
