<template>
  <div>
    <v-form ref="settlementForm" lazy-validation style="max-width: 600px">
      <v-text-field
        label="Name"
        prepend-icon="notes"
        v-model="settlement.name"
        :rules="[v => !!v || 'Bitte Namen angeben']"
        class="mb-1"
      />
      <v-text-field
        label="Beschreibung"
        prepend-icon="notes"
        v-model="settlement.description"
        class="mb-1"
      />
      <v-text-field
        label="Status"
        prepend-icon="notes"
        v-model="settlement.status"
        class="mb-1"
      />
      <v-menu
        v-model="start_datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="settlement.start_date"
            label="Startdatum"
            prepend-icon="event"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[v => !!v || 'Bitte Datum angeben']"
            class="mb-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="settlement.start_date"
          @input="start_datePicker = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="end_datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="settlement.end_date"
            label="Enddatum"
            prepend-icon="event"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[v => !!v || 'Bitte Datum angeben']"
            class="mb-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="settlement.end_date"
          @input="end_datePicker = false"
        ></v-date-picker>
      </v-menu>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountEditor",
  components: {},
  directives: {},
  props: {
    initialSettlement: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      settlement: {
        ...{},
        ...this.initialSettlement
      },
      start_datePicker: false,
      end_datePicker: false
    };
  },
  watch: {
    initialSettlement: function(newVal) {
      this.settlement = { ...this.settlement, ...newVal };
    }
  },
  methods: {
    validate() {
      return this.$refs.settlementForm.validate();
    },
    getSettlement() {
      return this.settlement;
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isTreasurer", "isStavo"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>

<style scoped></style>
