<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card flat>
      <v-card-title>
        Konto bearbeiten
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0">
          <div style="width: 600px" class="mr-md-12">
            <account-editor ref="accountEditor" :initial-account="account">
            </account-editor>
          </div>
        </v-row>
      </v-card-text>
      <v-divider class="mt-3" />
      <v-card-actions>
        <v-btn color="primary" elevation="0" @click="doAccountSubmission()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AccountEditor from "@/components/financial/AccountEditor";

export default {
  name: "AccountDetailView",
  components: { AccountEditor },
  methods: {
    ...mapActions("financial", ["updateAccount", "fetchAccounts"]),
    doAccountSubmission() {
      if (this.$refs.accountEditor.validate()) {
        let changedAccount = this.$refs.accountEditor.getAccount();
        this.updateAccount(changedAccount);
        this.$router.push("/financial/accounts");
      } else {
        this.$vuetify.goTo(1, { duration: 650 });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isStavo", "isTreasurer"]),
    ...mapGetters("financial", { accountGetter: "account" }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    account() {
      return this.accountGetter(parseInt(this.$route.params.accountId));
    }
  },
  mounted() {
    this.fetchAccounts();
  }
};
</script>

<style scoped></style>
