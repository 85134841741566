<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="settlements"
      item-key="id"
      class="elevation-1"
      :sort-by.sync="sortby"
      :sort-desc.sync="desc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            to="/financial/settlement/submission"
          >
            Neue Abrechnung
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          :to="'/financial/settlements/show/' + item.id"
        >
          <v-icon small>
            mdi-eye
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          icon
          :to="'/financial/settlements/' + item.id"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h6">
          Wirklich löschen?
        </v-card-title>

        <v-card-text>
          Soll die Abrechnung wirklich gelöscht werden?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="deleteDialog = false">
            Nein
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteButtonClicked(deleteId)">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Settlements",
  data() {
    return {
      deleteDialog: false,
      deleteId: null,
      filter: {},
      sortby: "order",
      desc: true
    };
  },
  components: {},

  computed: {
    settlements() {
      return this.$store.state.financial.settlements;
    },
    headers() {
      return [
        {
          text: "Abrechnung",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status"
        },
        {
          text: "Start",
          align: "start",
          sortable: true,
          value: "start_date"
        },
        {
          text: "Ende",
          align: "start",
          sortable: true,
          value: "end_date"
        },
        { text: "", align: "end", value: "actions", sortable: false }
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("financial", ["deleteSettlements"]),
    showDeleteConfirm(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    deleteButtonClicked(id) {
      this.deleteDialog = false;
      this.deleteSettlements(id);
    }
  }
};
</script>
